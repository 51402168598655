body {
    background-color: #111111;
    color: rgba(255, 255, 255, 0.85);
}

.ant-layout-header {
    background: #000000;
    height: 40px;
    line-height: 40px;
    padding: 0;
}

.ant-layout-header a {
    color: #999999;
}

.ant-layout-content {
    background-color: #111111;
}

.logo-block {
    text-align: center;
    position: relative;
    margin: 10px 0;
}

.logo-line {
    border-top: 2px solid #333333;
    position: absolute;
    top: 55px;
    width: 100%;
}

.logo-content {
    background-color: #111111;
    z-index: 1;
    position: relative;
    width: 230px;
    display: inline-block;
    color: #999999;
}

.logo-content img {
    width: 150px;
}

.logo-content p {
    margin: 5px;
}

.tab-block {

}

.tab-img {
    width: 90px;
    border-radius: 50px;
    border: 4px solid #333;
}

.ant-tabs-tab-active .tab-img {
    /*border: 4px solid #fecd0f;*/
}
.ant-tabs-ink-bar {
    background-color: #fecd0f!important;
}
.ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 25px;
}

.content {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 20px;
}

.button {
    background-color: #fecd0f;
    color: #333;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
}
.button:hover {
    background-color: #fecd0f;
    color: #333;
}
.elfsight-app-f5feb33a-627c-4857-b401-a1900b283cef .eapps-link{
    display: none!important;
}